<template>
  <v-app-bar fixed class="navBg" elevate-on-scroll>
    <DrawerComponent />
    <v-toolbar-items>
      <v-toolbar-title
        :class="$vuetify.breakpoint.smAndDown ? 'ml-1 pl-2 mr-4 ' : 'pr-5'"
        style="cursor: pointer"
        @click.prevent="$vuetify.goTo('#overview', { offset: 0 })"
      >
        <router-link class="router-link d-inline-flex" to="/">
          <v-img
            src="@/assets/logos/logoWhite.png"
            contain
            height="60px"
            width="60px"
            :class="
              routeName === 'admin' || routeName === 'settings' ? 'ml-5' : ''
            "
            style="z-index: 100"
            alt="Profile Photo"
          />
        </router-link>
      </v-toolbar-title>
    </v-toolbar-items>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn class="white--text" text to="/tourneys/">
        <v-icon left dark> mdi-trophy</v-icon> TOURNEYS
      </v-btn>

      <!-- <v-btn class="white--text" text to="/vlive/champions">
        <v-icon left dark> mdi-television-classic</v-icon> V-LIVE SOON
      </v-btn> -->
      <!-- <v-btn class="white--text" text to="/vpass/">
        <v-icon left dark> mdi-certificate</v-icon> V-PASS
      </v-btn> -->

      <!-- <v-btn class="white--text" text to="/giveaways/">
        <v-icon left dark> mdi-seal </v-icon>GIVEAWAYS
      </v-btn> -->

      <!-- <v-btn class="white--text" text to="/merch">
        <v-icon left dark> mdi-hanger</v-icon> MERCH
      </v-btn> -->
      <!-- <v-btn
        class="white--text"
        text
        to="/affiliates/"
        v-if="profileAffiliate || profileUsername === 'vforce'"
      >
        <v-icon left dark> mdi-account-multiple</v-icon> Affiliates
      </v-btn> -->
      <!-- <v-btn class="white--text" text to="/customs/">
        <v-icon left dark> mdi-trophy</v-icon> CUSTOMS
      </v-btn> -->
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <!-- <NavSocials class="mr-2" /> -->
    <v-toolbar-items>
      <v-btn disabled class="white--text hidden-xs-only" text to="/pricing/">
        <v-icon left dark class="hidden-xs-only">
          mdi-account-credit-card</v-icon
        >
        <span>Pricing</span>
      </v-btn>
      <v-btn
        class="white--text hidden-xs-only"
        color="purple"
        elevation="0"
        disabled
        to="/ranks/"
      >
        <v-icon left dark class="hidden-xs-only"> mdi-chevron-triple-up</v-icon>
        <span>Get Rank</span>
      </v-btn>
      <v-btn class="white--text hidden-xs-only" text to="/store/">
        <v-icon left dark :size="20"> mdi-cart</v-icon>
        <span>Store</span>
      </v-btn>
      <v-btn class="white--text hidden-xs-only" text v-if="user">
        <v-badge v-if="profileNotifications" bordered color="error" overlap>
          <v-icon dark> mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else dark> mdi-bell</v-icon>
      </v-btn>
    </v-toolbar-items>

    <DropdownComponent />
    <DrawerRightComponent class="hidden-sm-and-up" />

    <v-toolbar-items>
      <v-btn class="white--text" text to="/login" v-if="!user">
        <v-icon left dark class="hidden-xs-only"> mdi-login-variant</v-icon>
        LOGIN
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>
// import NavSocials from "@/components/navigation/NavSocials.vue";
import DropdownComponent from "@/components/dropdown/DropdownComponent.vue";
import { mapState } from "vuex";
import DrawerComponent from "@/components/navigation/DrawerComponent.vue";
import DrawerRightComponent from "@/components/navigation/DrawerRightComponent.vue";

export default {
  name: "NavigationComponent",
  components: {
    // NavSocials,
    DropdownComponent,
    DrawerComponent,
    DrawerRightComponent,
  },
  data() {
    return {
      defaultNav: [
        {
          name: "HOME",
          route: "/",
          streamer: true,
          auth: false,
          id: false,
        },
        {
          name: "STORE",
          route: "/store",
          streamer: true,
          auth: false,
          id: false,
        },

        {
          name: "TEAM",
          route: "/team",
          streamer: true,
          auth: false,
          id: false,
        },
        // {
        //   name: "SCHEDULE",
        //   route: "/schedule",
        //   streamer: this.profileStreamer,
        //   auth: true,
        //   id: false,
        // },
        // {
        //   name: "VLive",
        //   route: "/vlive/",
        //   streamer: true,
        //   auth: true,
        //   id: true,
        // },
      ],
    };
  },

  computed: {
    ...mapState(["liveStreamer", "routeName"]),
    ...mapState("UserStore", [
      "profileAffiliate",
      "profileUsername",
      "profileNotifications",
      "user",
    ]),
  },

  methods: {},
};
</script>
<style scoped>
:deep(.v-badge--bordered .v-badge__badge) {
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
}
:deep(.v-toolbar__content, .v-toolbar__extension) {
  padding: 4px 0px;
}
</style>
