import { db, rtdb, fb } from "@/firebase";
import firebase from "firebase/app";
import router from "../../router";

const state = {
  profileUserId: null,
  profileAdmin: null,
  adminLevel: null,
  profileUsername: "",
  profileEmail: null,
  profileFirstName: null,
  profileLastName: null,
  profileInitials: null,
  profileMode: null,
  profileState: null,
  profileCreated_at: null,
  profileReferral: null,
  profilePhoto: null,
  profileRank: null,
  profileExpList: [],
  profileTotalExp: 0,
  profileStepsCompleted: 0,
  profileDailyExp: 0,
  profileStreamSchedule: null,
  profileFollowers: null,
  profileSocials: null,
  profileStreamer: null,
  profileSubscription: null,
  profileAllowEvent: false,
  profileXp: null,
  profileLevel: null,
  profileYoutubeShorts: null,
  profileYoutubeVideos: null,
  profileYoutubeVods: null,
  profileStepsTime: null,
  profileDisplayName: null,
  profileDiscordUsername: null,
  profileFortniteUsername: null,
  profileTwitterUsername: null,
  profileTwitterAccessToken: null,
  profileTwitterSecretAccessToken: null,
  profileTwitterUid: null,
  profileTwitterStatus: null,
  profileSteamUsername: null,
  profileDiscordId: null,
  profileType: null,
  profileAdminLevel: null,
  profileDetails: null,
  profileLiveLink: null,
  profilePlatform: null,
  profileStripeId: null,
  profileDailyChallenges: null,
  profileAffiliate: null,
  profileSeniorAdmin: null,
  profileTourneys: null,
  isOfflineForDatabase: null,
  isOnlineForDatabase: null,
  buildPresenceLoaded: null,
  user: null,
  usersState: [],
  userState: {},
  // Rtdb user
  rtdbAccount: {},
  // Loaded state
  userLoaded: false,
  rtdbUserLoaded: false,
  userStateLoaded: false,
  profileCoins: 0,
  profileCredits: 0,
};

const mutations = {
  SET_USER(state, payload) {
    state.profileUserId = payload.data().userId;
    state.profileEmail = payload.data().email;
    state.profileUsername = payload.data().username;
    state.profileDisplayName = payload.data().displayName;
    state.profileDiscordUsername = payload.data().discordUsername;
    state.profileTwitterUsername = payload.data().twitterUsername;
    state.profileTwitterAccessToken = payload.data().accessToken;
    state.profileAdmin = payload.data().admin;
    state.profileTwitterSecretAccessToken =
      payload.data().twitterSecretAccessToken;
    state.profileTwitterUid = payload.data().twitterUid;
    state.profileTwitterStatus = payload.data().twitterStatus;
    state.profileFortniteUsername = payload.data().fortniteUsername;
    state.profileMainGame = payload.data().mainGame;
    state.profileSteamUsername = payload.data().steamUsername;
    state.profileDiscordId = payload.data().discordId;
    state.profileType = payload.data().accountType;
    state.profileFirstName = payload.data().firstName;
    state.profileLastName = payload.data().lastName;
    state.profilePhoto = payload.data().profilePhoto;
    state.profileMode = payload.data().mode;
    state.profileCreated_at = payload.data().created_at;
    state.profileReferral = payload.data().referral;
    state.profileAdminLevel = payload.data().adminLevel;
    state.profileDetails = payload.data().details;
    state.profileRank = payload.data().rank;
    state.profileFollowers = payload.data().followers;
    state.profileSocials = payload.data().socials;
    state.profileLiveLink = payload.data().liveLink;
    state.profileStreamer = payload.data().streamer;
    state.profileStreamSchedule = payload.data().streamSchedule;
    state.profileYoutubeShorts = payload.data().youtubeShorts;
    state.profileYoutubeVideos = payload.data().youtubeVideos;
    state.profileYoutubeVods = payload.data().youtubeVods;
    state.profilePlatform = payload.data().platform;
    state.profileStripeId = payload.data().stripeId;
    state.profileDailyChallenges = payload.data().challenges;
    if (payload.data().affiliate) {
      state.profileAffiliate = payload.data().affiliate;
    }
  },
  SET_USER_ADMIN(state) {
    state.profileAdmin = true;
  },
  SET_USER_ADMIN_SENIOR(state) {
    state.profileSeniorAdmin = true;
  },
  SET_USER_INITIALS(state) {
    if (state.profileFirstName && state.profileLastName) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join("") +
        state.profileLastName.match(/(\b\S)?/g).join("");
    }
  },
  SET_CURRENT_RTDB_ACCOUNT(state, payload) {
    state.rtdbAccount = payload;
  },
  UPDATE_USER(state, payload) {
    state.user = payload;
  },
  SET_USERS_STATE_DATA(state, payload) {
    state.usersState.push(payload);
  },
  SET_USER_STATE(state, payload) {
    if (payload.val().state) {
      state.userState = payload.val().state;
    }
  },

  SET_RTDB_PROFILE_INFO(state, payload) {
    state.profileCoins = payload.val().coins;
    state.profileCredits = payload.val().credits;
  },
  SET_USER_TOURNEYS(state, payload) {
    if (payload && payload.list) {
      state.profileTourneys = payload;
    }
  },
  USER_LOADED(state) {
    state.userLoaded = true;
  },
  RTDB_USER_LOADED(state) {
    state.rtdbUserLoaded = true;
  },
  USERS_STATE_LOADED(state) {
    state.userStateLoaded = true;
  },
  BUILD_USER_PRESENCE_LOADED(state) {
    state.buildPresenceLoaded = true;
  },
  // Setters
  SET_PROFILE_USERNAME(state, newUsername) {
    state.profileUsername = newUsername;
  },
};

const getters = {};

const actions = {
  async GET_USER({ commit }, user) {
    const dataBase = await db.collection("accounts").doc(user.uid);
    const dbResults = await dataBase.get();
    if (dbResults.exists) {
      commit("SET_USER", dbResults);
      if (dbResults.data().admin && dbResults.data().adminLevel === "Mod") {
        commit("SET_USER_ADMIN");
      } else if (
        dbResults.data().admin &&
        dbResults.data().adminLevel === "Manager"
      ) {
        commit("SET_USER_ADMIN_SENIOR");
      }
      commit("SET_USER_INITIALS");
      commit("USER_LOADED");
    }
  },
  async GET_CURRENT_RTDB_USER({ commit }, user) {
    const dataBase = await rtdb.ref("/accounts/" + user.uid);
    dataBase.once("value", function (dbResults) {
      commit("SET_RTDB_PROFILE_INFO", dbResults);
    });
    commit("RTDB_USER_LOADED");
  },
  async GET_CURRENT_RTDB_ACCOUNT({ commit }, search) {
    await rtdb
      .ref("/accounts/")
      .orderByChild("email")
      .equalTo(search)
      .once("value")
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const data = childSnapshot.val();
            commit("SET_CURRENT_RTDB_ACCOUNT", data);
          });
        } else {
          await rtdb
            .ref("/accounts/")
            .orderByChild("username")
            .equalTo(search)
            .once("value")
            .then((snapshot) => {
              if (snapshot.exists()) {
                snapshot.forEach((childSnapshot) => {
                  const data = childSnapshot.val();
                  commit("SET_CURRENT_RTDB_ACCOUNT", data);
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    commit("RTDB_CURRENT_PROFILE_LOADED");
  },
  async GET_USER_TOURNEYS({ commit }, userId) {
    const dataBase = await db.collection("tourneysFn").doc(userId);
    const dbResults = await dataBase.get();
    const tourneys = dbResults.data();
    commit("SET_USER_TOURNEYS", tourneys);
  },
  async GET_USERS_STATE({ commit, state }) {
    let getData = rtdb.ref("/status/").orderByChild("state");
    getData.on("value", function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        if (
          !state.usersState.some(
            (account) => account.userId === childSnapshot.val().userId
          ) &&
          childSnapshot.val().userId
        ) {
          const data = {
            state: childSnapshot.val().state,
            currentRoute: router.currentRoute.name,
            last_changed: childSnapshot.val().last_changed,
            username: childSnapshot.val().username,
            streamer: childSnapshot.val().streamer,
            profileInitials: childSnapshot.val().profileInitials,
            currentRouteId: router.currentRoute.params.id,
            profilePhoto: childSnapshot.val().profilePhoto,
            rank: childSnapshot.val().rank,
            userId: childSnapshot.val().userId,
          };
          commit("SET_USERS_STATE_DATA", data);
        }
      });
    });
    commit("USERS_STATE_LOADED");
  },
  async GET_USER_STATE({ commit }) {
    let getData = await rtdb.ref("/status/" + fb.auth().currentUser.uid);
    getData.on("value", function (snapshot) {
      let data = snapshot;
      commit("SET_USER_STATE", data);
    });
  },
  async BUILD_USER_PRESENCE({ commit, state }, user) {
    let userStatusDatabaseRef = await rtdb.ref("/status/" + user);
    state.isOfflineForDatabase = {
      state: "offline",
      currentRoute: router.currentRoute.name || "",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      username: state.profileUsername,
      streamer: state.profileStreamer,
      profileInitials: state.profileInitials,
      profilePhoto: state.profilePhoto,
      userId: state.profileUserId,
    };
    state.isOnlineForDatabase = {
      state: "online",
      currentRoute: router.currentRoute.name || "",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      username: state.profileUsername,
      streamer: state.profileStreamer,
      profileInitials: state.profileInitials,
      profilePhoto: state.profilePhoto,
      userId: state.profileUserId,
    };

    if (router.currentRoute.params.id) {
      state.isOfflineForDatabase = {
        ...state.isOfflineForDatabase,
        currentRouteId: router.currentRoute.params.id,
      };
      state.isOnlineForDatabase = {
        ...state.isOnlineForDatabase,
        currentRouteId: router.currentRoute.params.id,
      };
    }
    if (state.profileRank) {
      state.isOnlineForDatabase.rank = state.profileRank;
      state.isOfflineForDatabase.rank = state.profileRank;
    }

    rtdb.ref(".info/connected").on("value", function (snapshot) {
      if (snapshot.val() == false) {
        return;
      }
      userStatusDatabaseRef
        .onDisconnect()
        .update(state.isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.update(state.isOnlineForDatabase);
        });
    });
    commit("BUILD_USER_PRESENCE_LOADED");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
