import { db, rtdb } from "@/firebase";

const state = {
  affiliateTweetsRequests: [],
  affiliateNoApiTweetsRequests: [],
  affiliateTweetsRequestsLoaded: false,
  affiliateTweetsNoApiRequestsLoaded: false,
  affiliatesLeaderboard: null,
  affiliateExpTweets: null,
  affiliateProfilesExp: [],
  affiliateExpList: [],
};

const mutations = {
  SET_AFFILIATE_PROFILES_EXP(state, payload) {
    if (payload.expList && payload.expList.length) {
      const index = payload.expList.findIndex((item) => {
        return item.username === state.profileUsername;
      });
      if (index !== -1) {
        state.affiliateProfilesExp.push({
          ...payload.expList[index],
          affiliate: payload.username,
        });
      } else {
        let updateUser = {
          username: state.profileUsername,
          twitterUsername: state.profileTwitterUsername,
          userExp: 0,
          affiliate: payload.username,
        };
        state.affiliateProfilesExp.push(updateUser);
      }
    } else {
      let updateUser = {
        username: state.profileUsername,
        twitterUsername: state.profileTwitterUsername,
        userExp: 0,
        affiliate: payload.username,
      };
      state.affiliateProfilesExp.push(updateUser);
    }
  },
  SET_AFFILIATES_LEADERBOARD(state, payload) {
    state.affiliatesLeaderboard = payload.data().leaderboard;
  },
  SET_AFFILIATE_TWEETS_LIST(state, payload) {
    payload.tweetsCount = payload.tweets.length;
    state.affiliateExpTweets.push(payload);
  },
  SET_AFFILIATE_TWEETS_REQUESTS(state, payload) {
    state.affiliateTweetsRequests.push(payload);
  },
  SET_AFFILIATE_NO_API_TWEETS_REQUESTS(state, payload) {
    state.affiliateNoApiTweetsRequests.push(payload);
  },
  AFFILIATE_TWEETS_REQUESTS_LOADED(state) {
    state.affiliateTweetsRequestsLoaded = true;
  },
  AFFILIATE_NO_API_TWEETS_REQUESTS_LOADED(state) {
    state.affiliateTweetsNoApiRequestsLoaded = true;
  },
  SET_AFFILIATE_EXP_LIST(state, payload) {
    let affiliateExpSteps = {
      userId: payload.userId,
      step: payload.step,
      currentStep: payload.currentStep,
    };
    state.affiliateExp.push(affiliateExpSteps);
    payload.expList = [...payload.expList].sort(
      (a, b) => b.userExp - a.userExp
    );

    let affiliateExpList = {
      userId: payload.userId,
      expList: payload.expList,
    };
    state.affiliateExpList.push(affiliateExpList);
  },
};

const getters = {};

const actions = {
  async GET_AFFILIATES_EXP({ commit, state }) {
    let dataBase = await db.collection("exp").where("affiliates", "==", true);
    const dbResults = await dataBase.get();
    dbResults.forEach((doc) => {
      if (
        doc.data().userId &&
        !state.affiliatesExpTweets.some(
          (affiliate) => affiliate.userId === doc.data().userId
        )
      ) {
        let affiliate = doc.data();
        let tweetsList = [];
        let completedSteps = 1;
        let pendingSteps = 1;
        affiliate.currentStep = 1;
        affiliate.pendingCount = 0;
        if (affiliate.tweets && affiliate.tweets.length) {
          affiliate.tweets.forEach((item) => {
            if (
              item.completedList &&
              item.completedList.includes(state.profileTwitterUsername)
            ) {
              affiliate.completedSteps = completedSteps++;
              affiliate.currentStep = affiliate.currentStep + 1;
              item.stepCompleted = true;
              item.stepStatus = "Completed";
            } else if (
              (item.pendingList &&
                item.pendingList.includes(state.profileTwitterUsername)) ||
              (item.requests &&
                item.requests.includes(state.profileTwitterUsername))
            ) {
              affiliate.pendingCount = pendingSteps++;
              item.stepCompleted = true;
              affiliate.currentStep = affiliate.currentStep + 1;
              item.stepStatus = "Pending";
              item.btnStatus = "Pending";
            } else {
              item.stepCompleted = false;
              item.stepStatus = "Complete";
              affiliate.currentStep = affiliate.currentStep + 0;
            }

            item.link =
              "https://www.twitter.com/" +
              affiliate.twitterUsername +
              "/status/" +
              item.id;

            tweetsList.push(item);
          });
        }
        let affiliateExpTweets = {
          userId: affiliate.userId,
          tweets: tweetsList,
        };
        commit("SET_AFFILIATE_PROFILES_EXP", affiliate);
        commit("SET_AFFILIATE_EXP_LIST", affiliate);
        commit("SET_AFFILIATE_TWEETS_LIST", affiliateExpTweets);
        commit("SET_AFFILIATES_EXP", affiliate);
      }
    });

    commit("AFFILIATES_EXP_LOADED");
  },
  async GET_AFFILIATES_LEADERBOARD({ commit }) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    let dataBase = db.collection("exp").doc(`${currentYear}-${currentMonth}`);
    const dbResults = await dataBase.get();
    if (dbResults.exists) {
      commit("SET_AFFILIATES_LEADERBOARD", dbResults);
    }
    commit("AFFILIATES_LEADERBOARD_LOADED");
  },
  async GET_ACCOUNT_WON_GIVEAWAYS({ commit, state }, user) {
    state.accountWonGiveaways = [];
    let dataBase = await db
      .collection("giveaways")
      .where("winners", "array-contains", user);
    const dbResults = await dataBase.get();
    dbResults.forEach((doc) => {
      if (
        !state.accountWonGiveaways.some(
          (giveaway) => giveaway.docId === doc.docId
        )
      ) {
        commit("SET_ACCOUNT_WON_GIVEAWAYS", doc);
      }
    });
  },
  async GET_AFFILIATE_TWEETS_REQUESTS({ commit }, userId) {
    const usersRef = await rtdb.ref("/newRequests");

    usersRef
      .orderByChild("userId")
      .equalTo(userId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          commit("SET_AFFILIATE_TWEETS_REQUESTS", data);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    commit("AFFILIATE_TWEETS_REQUESTS_LOADED");
  },
  async GET_AFFILIATE_NO_API_TWEETS_REQUESTS({ commit }, userId) {
    const usersRef = await rtdb.ref("/noApiRequests");
    usersRef
      .orderByChild("userId")
      .equalTo(userId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          commit("SET_AFFILIATE_NO_API_TWEETS_REQUESTS", data);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    commit("AFFILIATE_NO_API_TWEETS_REQUESTS_LOADED");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
