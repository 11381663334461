var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:_vm.routeName === 'leaderboard' ? 'mt-10 ' : ' mt-0 pt-0 mx-0 px-0',attrs:{"fluid":"","grid-list-xs":""}},[(_vm.leaderboard.type === 'exp')?_c('RankingsSearchBar',{attrs:{"rankings":_vm.leaderboard}}):_vm._e(),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"11","md":_vm.routeName === 'leaderboard' ? '6' : '12'}},[_c('v-card',{staticClass:"transparent",attrs:{"elevation":"4"}},[_c('div',{staticClass:"scrollable-table"},[_c('v-simple-table',{staticClass:"pa-0 ma-0 transparent",attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("TOP")]),(_vm.leaderboard.drawer)?_c('th',{staticClass:"text-left"},[_vm._v(" Leaderboard ")]):_vm._e(),(_vm.leaderboard.type === 'exp' && _vm.leaderboard.drawer)?_c('th',{staticClass:"text-left"},[_vm._v(" EXP ")]):_vm._e(),(_vm.leaderboard.type === 'coins' && _vm.leaderboard.drawer)?_c('th',{staticClass:"text-left"},[_vm._v(" COINS ")]):_vm._e(),(
                      _vm.leaderboard.type === 'credits' && _vm.leaderboard.drawer
                    )?_c('th',{staticClass:"text-left"},[_vm._v(" CREDITS ")]):_vm._e()])]),_c('tbody',[_vm._l((_vm.leaderboard.rankings),function(item,i){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(i < 20),expression:"i < 20"}],key:i},[_c('td',{staticClass:"pl-6"},[_vm._v(_vm._s(i + 1))]),(_vm.leaderboard.drawer)?_c('td',[(i === 0)?_c('v-btn',{staticClass:"ma-0 black--text",attrs:{"x-small":"","to":`/${item.username}`,"color":"#FFD700"},on:{"click":_vm.closeDrawer}},[(item.username)?_c('span',[_vm._v(_vm._s(item.username))]):_c('span',[_vm._v(_vm._s(item.twitterUsername))])]):_vm._e(),(i === 1)?_c('v-btn',{staticClass:"ma-0 black--text",attrs:{"x-small":"","to":`/${item.username}`,"color":"#C0C0C0"},on:{"click":_vm.closeDrawer}},[(item.username)?_c('span',[_vm._v(_vm._s(item.username))]):_c('span',[_vm._v(_vm._s(item.twitterUsername))])]):_vm._e(),(i === 2)?_c('v-btn',{staticClass:"ma-0 black--text",attrs:{"x-small":"","to":`/${item.username}`,"color":"#A37E49"},on:{"click":_vm.closeDrawer}},[(item.username)?_c('span',[_vm._v(_vm._s(item.username))]):_c('span',[_vm._v(_vm._s(item.twitterUsername))])]):_vm._e(),(i > 2)?_c('v-btn',{staticClass:"ma-0",attrs:{"x-small":"","to":`/${item.username}`,"outlined":""},on:{"click":_vm.closeDrawer}},[(item.username)?_c('span',[_vm._v(_vm._s(item.username))]):_c('span',[_vm._v(_vm._s(item.twitterUsername))])]):_vm._e()],1):_vm._e(),(_vm.leaderboard.type === 'exp' && _vm.leaderboard.drawer)?_c('td',[_vm._v(" "+_vm._s(item.userExp)+" ")]):_vm._e(),(_vm.leaderboard.type === 'coins' && _vm.leaderboard.drawer)?_c('td',[_vm._v(" "+_vm._s(item.coins)+" ")]):_vm._e(),(
                      _vm.leaderboard.type === 'credits' && _vm.leaderboard.drawer
                    )?_c('td',[_vm._v(" "+_vm._s(item.credits)+" ")]):_vm._e()])}),(
                    !_vm.isUsernameInRankings &&
                    _vm.leaderboard.type === 'exp' &&
                    _vm.leaderboard.drawer
                  )?_c('tr',[(_vm.leaderboard.rankings.length)?_c('td',{staticClass:"pl-6"},[_vm._v(" "+_vm._s(_vm.leaderboard.rankings.length + 1)+" ")]):_c('td',{staticClass:"pl-6"},[_vm._v("0")]),_c('td',[_c('v-btn',{staticClass:"ma-0",attrs:{"x-small":"","to":'/' + _vm.profileUsername,"outlined":""},on:{"click":_vm.closeDrawer}},[_c('span',[_vm._v(_vm._s(_vm.profileUsername))])])],1),_c('td',[_c('span',[_vm._v("0")])])]):_vm._e()],2)]},proxy:true}])})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }