import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB2tn61Tj_WOPuCcN7s64jbbHow356RDY0",
  authDomain: "vforcegg-4a9bb.firebaseapp.com",
  databaseURL: "https://vforcegg-4a9bb-default-rtdb.firebaseio.com",
  projectId: "vforcegg-4a9bb",
  storageBucket: "vforcegg-4a9bb.appspot.com",
  messagingSenderId: "1090590763957",
  appId: "1:1090590763957:web:45aef97c5590a96f5fc7fc",
  measurementId: "G-GVFRQEEGRD",
};
// const firebaseConfigEduprivate = {
//   apiKey: "AIzaSyBE364tAUNjS5a85O-90Op0CwWamDE0Pf8",
//   authDomain: "eduprivate2020.firebaseapp.com",
//   databaseURL: "https://eduprivate2020.firebaseio.com",
//   projectId: "eduprivate2020",
//   storageBucket: "eduprivate2020.appspot.com",
//   messagingSenderId: "18880745761",
//   appId: "1:18880745761:web:1967de915b4c979daf6e60",
//   measurementId: "G-79GXBVR3P7",
// };
var twitterProvider = new firebase.auth.TwitterAuthProvider();
twitterProvider.setCustomParameters({
  lang: "en",
});
const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const rtdb = firebase.database();
const fn = firebase.functions();
const auth = firebase.auth();

const timeStamp = firebase.firestore.FieldValue.serverTimestamp;
const fieldValue = firebase.firestore.FieldValue;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  db,
  fb,
  timeStamp,
  fieldValue,
  rtdb,
  fn,
  auth,
  twitterProvider,
  googleAuthProvider,
  twitterAuthProvider,
};
