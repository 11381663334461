import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DateFilter from "./filters/date";
import VueSocialSharing from "vue-social-sharing";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.filter("date", DateFilter);
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  vuetify,
  VueSocialSharing,
  render: (h) => h(App),
}).$mount("#app");
