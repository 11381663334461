<template>
  <div>
    <v-btn
      icon
      color="white"
      dark
      v-show="!drawerRight"
      @click.stop="drawerRight = !drawerRight"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="drawerRight"
      absolute
      temporary
      right
      style="position: fixed; top: 0; z-index: 20"
      height="100vh"
      dark
    >
      <v-container grid-list-xs>
        <v-row justify="end">
          <v-card-title class="pt-2 white--text">Navigation</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="white"
            dark
            v-show="drawerRight"
            @click.stop="drawerRight = !drawerRight"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item to="/home">
          <v-list-item-icon>
            <v-icon> mdi-home-analytics</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>HOME</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/store">
          <v-list-item-icon>
            <v-icon>mdi-store-check-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>STORE: COINS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/merch">
          <v-list-item-icon>
            <v-icon>mdi-hanger</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>MERCH</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/giveaways">
          <v-list-item-icon>
            <v-icon>mdi-seal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>GIVEAWAYS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/tourneys">
          <v-list-item-icon>
            <v-icon>mdi-trophy</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>TOURNEYS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/affiliates">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>AFFILIATES</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ranks">
          <v-list-item-icon>
            <v-icon>mdi-chevron-triple-up</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>GET RANK</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Drawer ride side -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "NavigationComponent",
  components: {},
  data() {
    return {
      drawer: true,
      drawerRight: false,
      mini: true,
    };
  },

  computed: {
    ...mapState([
      "user",
      "accountsState",
      "profileUsername",
      "profileInitials",
      "profilePhoto",
      "liveStreamer",
    ]),
    orderedUsers: function () {
      return _.orderBy(this.accountsState, ["state"], ["desc"]);
    },
  },

  methods: {},
};
</script>
<style scoped>
:deep(.v-badge--bordered .v-badge__badge) {
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
}
</style>
