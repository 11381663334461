<template>
  <!-- User is logged IN -->
  <v-toolbar-items v-if="user && userLoaded">
    <v-menu v-model="menu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn
          color="transparent"
          @click="show = !show"
          v-on="on"
          class="pb-0 pl-2 pr-0"
          elevation="0"
        >
          <v-container fluid grid-list-xs class="px-0">
            <v-row
              :class="
                $vuetify.breakpoint.xs
                  ? ' ma-0 pa-0 pb-2 xsWidth'
                  : 'ma-0 pa-0 mdWidth'
              "
            >
              <v-col cols="4" offset-sm="2" class="mb-0 pb-2 pl-0 ml-0 mr-1">
                <v-badge
                  bordered
                  bottom
                  :color="
                    profileState !== 'online'
                      ? 'grey accent-4'
                      : 'green accent-4'
                  "
                  :dot="profileState !== 'online'"
                  offset-x="25"
                  offset-y="10"
                >
                  <v-avatar
                    size="40"
                    class="mr-2 white pl-0"
                    v-if="profilePhoto"
                  >
                    <v-img
                      cover
                      :src="profilePhoto"
                      :lazy-src="profilePhoto"
                      alt="alt"
                    />
                  </v-avatar>
                  <v-avatar size="40" class="mr-2 white" v-else>
                    <h4 class="white--text">{{ profileInitials }}</h4>
                  </v-avatar>
                </v-badge>
              </v-col>

              <v-col cols="3" class="mt-1 mb-0 pl-0 hidden-xs-only">
                <div class="d-inline-flex">
                  <div class="mr-1 pl-0 hidden-xs-only">
                    <v-chip
                      x-small
                      color="blue"
                      label
                      class="white--text mb-1 ml-0"
                      ><h5>LVL {{ profileLevel }}</h5></v-chip
                    >
                    <!-- <v-progress-linear
                      :value="profileExpProgressBar"
                      height="10"
                      striped
                      color="blue"
                      class="expMarLeft"
                    ></v-progress-linear> -->
                    <h6 class="white--text ml-0 pl-0 expPadTop text-right">
                      {{ profileCredits }}
                      <v-icon color="white" small>mdi-circle-multiple</v-icon>
                    </h6>
                  </div>

                  <v-icon
                    class="hidden-xs-only"
                    color="white"
                    :class="$vuetify.breakpoint.xs ? 'ml-5 mt-3' : ''"
                    >{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-btn>
      </template>

      <v-list style="padding: 0px; width: 270px" class="mt-1" dark>
        <v-list-item-group>
          <v-list-item
            :to="`/${profileUsername}`"
            @click="(menu = false), GET_ACCOUNT(profileUsername)"
          >
            <v-container grid-list-xs>
              <v-row class="pb-1">
                <v-col cols="2" class="py-2 mt-1">
                  <v-avatar size="50" class="mr-2 white" v-if="profilePhoto">
                    <v-img
                      contain
                      :src="profilePhoto"
                      :lazy-src="profilePhoto"
                      alt="alt"
                    />
                  </v-avatar>
                  <v-avatar size="55" class="mr-2 white" v-else>
                    <h3 class="white--text">{{ profileInitials }}</h3>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="py-2 hidden-sm-and-up">
                  <div class="mr-1 pl-8 hidden-sm-and-up">
                    <div class="d-inline-flex">
                      <p
                        class="font-weight-black white--text text-uppercase mb-0"
                      >
                        {{ profileUsername }}
                      </p>
                      <v-chip
                        x-small
                        color="blue"
                        label
                        class="white--text ml-7 mt-1 hidden-xs-only"
                        ><h5>LVL {{ profileLevel }}</h5></v-chip
                      >
                    </div>
                    <v-progress-linear
                      :value="profileExpProgressBar"
                      height="10"
                      striped
                      color="blue"
                      class="expMarLeft"
                    ></v-progress-linear>
                  </div>
                  <v-row justify="center" class="py-4 ml-1">
                    <h6 class="white--text pl-8 pt-1 expPadTop text-right">
                      {{ profileCredits }}
                      <v-icon color="white" small>mdi-circle-multiple</v-icon>
                    </h6>

                    <v-spacer></v-spacer>
                    <v-btn class="ml-2 mr-4 blue mt-1" x-small dark
                      >Profile</v-btn
                    >
                    <!-- <v-btn
                      color="red"
                      class="mt-1 pt-0 white--text"
                      x-small
                      disabled
                      v-if="liveStreamer && liveStreamer.live && subscription"
                      >Live</v-btn
                    >
                    <v-btn
                      v-if="liveStreamer && !liveStreamer.live && subscription"
                      class="mt-1 pt-0"
                      x-small
                      disabled
                      >Offline</v-btn
                    > -->
                  </v-row>
                </v-col>
                <v-col cols="10" class="py-2 hidden-xs-only">
                  <p
                    class="font-weight-black pl-6 white--text text-uppercase mb-0"
                    :class="subscription ? '' : 'mt-1'"
                  >
                    {{ profileUsername }}
                  </p>
                  <div class="d-inline-flex">
                    <v-btn class="ml-6 mr-2 mt-1 blue" x-small dark
                      >Profile page</v-btn
                    >
                    <!-- <v-btn
                      color="red"
                      class="mt-1 pt-0 white--text"
                      x-small
                      disabled
                      v-if="liveStreamer && liveStreamer.live && subscription"
                      >Live</v-btn
                    >
                    <v-btn
                      v-if="liveStreamer && !liveStreamer.live && subscription"
                      class="mt-1 pt-0"
                      x-small
                      disabled
                      >Offline</v-btn
                    > -->
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>
          <v-divider></v-divider>
          <!-- <div v-if="subscription">
            <v-list-item
              :to="'/' + profileUsername + '/chat'"
              class="pointer"
              @click="menu = false"
            >
              <v-list-item-icon class="mx-3 my-3">
                <v-icon color="purple">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pt-4">
                <v-list-item-title class="black--text"
                  >Profile Chat</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div> -->
          <!-- <div v-if="subscription">
            <v-list-item style="height: 30px">
              <v-list-item-icon class="mx-3 my-3">
                <v-icon color="purple"> mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="black--text"
                  ><span v-if="liveStreamer && !liveStreamer.live">Start</span>
                  <span v-else>End</span> Stream
                </v-list-item-title>
              </v-list-item-content>
              <v-switch
                color="purple"
                class="ml-3"
                v-model="liveStreamer"
                @click="toggleLiveMode()"
              ></v-switch>
            </v-list-item>
          </div> -->
          <div>
            <!-- <v-list-item style="height: 30px">
              <v-list-item-icon class="mx-3 my-3">
                <v-icon color="purple"> mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Dark theme</v-list-item-title
                >
              </v-list-item-content>
              <v-switch
                color="purple"
                class="ml-3"
                v-model="mode"
                @click="toggleProfileMode(mode)"
              ></v-switch>
            </v-list-item> -->
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              offset-x
              v-if="subscription"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item style="height: 30px" v-bind="attrs" v-on="on">
                  <v-list-item-icon class="mx-3 my-3">
                    <v-icon color="purple"> mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="white--text"
                      >Switch Games</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-card width="275px">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>FORTNITE</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        :class="fav ? 'red--text' : ''"
                        icon
                        @click="fav = !fav"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>CSGO</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        :class="fav ? 'red--text' : ''"
                        icon
                        @click="fav = !fav"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>FIFA</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        :class="fav ? 'red--text' : ''"
                        icon
                        @click="fav = !fav"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text @click="menu1 = false"> Cancel </v-btn>
                  <v-btn color="primary" text @click="menu1 = false">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>

          <v-divider></v-divider>
          <div
            v-for="(item, i) in adminNavItems"
            :key="'A' + i"
            v-show="(user && profileAdmin) || profileSeniorAdmin"
          >
            <h5 class="mb-0 pt-2 pl-8" v-if="item.subheader">
              {{ item.subheader }}
            </h5>
            <v-list-item
              :to="item.route"
              class="pointer"
              @click="menu = false"
              v-else
            >
              <v-list-item-icon class="mx-3 my-3">
                <v-icon v-text="item.icon" color="purple"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pt-3">
                <v-list-item-title
                  v-text="item.text"
                  class="white--text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="item.divider"></v-divider>
          </div>

          <div v-for="(item, i) in defaultNavItems" :key="'B' + i">
            <h5 class="mb-0 pt-2 pl-8" v-if="item.subheader">
              {{ item.subheader }}
            </h5>
            <v-list-item
              :to="item.route"
              class="pointer"
              @click="menu = false"
              v-else-if="item.type === 'switch'"
            >
              <v-list-item-icon class="mx-3 my-3">
                <v-icon v-text="item.icon" color="purple"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pt-3">
                <v-list-item-title
                  v-text="item.text"
                  class="white--text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :to="item.route"
              class="pointer"
              @click="menu = false"
              v-else
            >
              <v-list-item-icon class="mx-3 my-3">
                <v-icon v-text="item.icon" color="purple"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pt-3">
                <v-list-item-title
                  v-text="item.text"
                  class="white--text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="item.divider"></v-divider>
          </div>

          <v-list-item
            style="cursor: pointer"
            class="py-1"
            @click="logout(), (menu = false)"
          >
            <v-list-item-title>
              <v-icon left color="error" class="mx-3"
                >mdi-logout-variant</v-icon
              >
              <span>Logout</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

import { db, fb, rtdb } from "@/firebase";

export default {
  name: "DropdownComponent",
  data() {
    return {
      show: false,
      menu: false,
      menu1: false,
      fav: true,

      defaultNavItems: [
        {
          subheader: "Profile",
          icon: "mdi-account-arrow-right-outline",
        },

        {
          text: "Settings",
          type: "route",
          route: "/settings",
          icon: "mdi-account-arrow-right-outline",
          divider: true,
        },
      ],
      adminNavItems: [
        {
          subheader: "Team",
          icon: "mdi-account-arrow-right-outline",
        },
        {
          text: "Admin Hub",
          route: "/admin#analytics",
          icon: "mdi-account-arrow-right-outline",
        },
        {
          text: "Rankings",
          route: "/admin#rankings",
          icon: "mdi-account-arrow-right-outline",
          divider: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(["liveStreamer", "subscription", "adminLevel"]),
    ...mapState("UserStore", [
      "user",
      "userLoaded",
      "profilePhoto",
      "profileAccountType",
      "profileUsername",
      "profileUserId",
      "profileEmail",
      "profileFirstName",
      "profileLastName",
      "profileType",
      "profileInitials",
      "profileAdmin",
      "profileState",
      "profileTotalExp",
      "profileLevel",
      "profileCredits",
      "profileSubscription",
      "profileSeniorAdmin",
    ]),
    ...mapGetters(["profileMode"]),
    mode: {
      get() {
        return this.profileMode;
      },
      set(newName) {
        return newName;
      },
    },
    profileLevel() {
      return Math.trunc(this.profileTotalExp / 100);
    },
    profileExpProgressBar: {
      get() {
        let number = this.profileTotalExp / 100;
        let getDecimalVal = number.toString().indexOf(".");
        let decimalPart = number.toString().substring(getDecimalVal + 1);
        if (decimalPart.length === 1) {
          decimalPart = decimalPart.concat("0");
        }
        return +decimalPart;
      },
      set(newValue) {
        this.$store.commit("UPDATE_PROFILE_TOTAL_EXP", newValue);
      },
    },
  },

  methods: {
    ...mapActions("AccountStore", ["GET_ACCOUNT"]),

    toggleLiveMode() {
      let updateLive = {
        live: !this.liveStreamer.live,
      };
      rtdb.ref("streamers/" + this.profileUsername).update(updateLive);
    },
    toggleProfileMode(mode) {
      let newMode = !mode;
      let update = {
        mode: newMode,
      };
      db.collection("accounts").doc(this.profileUserId).update(update);
      this.$store.commit("UPDATE_PROFILE_MODE", newMode);
    },

    logout() {
      fb.auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        });
    },
  },
};
</script>
<style scoped>
:deep(.v-badge--bordered .v-badge__badge) {
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
}
.expPadTop {
  padding-top: 2px;
}
.expMarLeft {
  margin-left: 3px;
}
.xsWidth {
  width: 60px;
}
.mdWidth {
  width: 145px;
}
</style>
