<template>
  <v-app>
    <Navigation />

    <v-main
      :class="profileMode === false ? ['app'] : ['mainBg']"
      class="mainBg pa-0"
    >
      <!-- Spinner element -->
      <div v-if="showSpinner" class="spinner">
        <!-- Your spinner component or HTML/CSS for the spinner -->
        Loading...
      </div>
      <router-view :key="$route.fullPath" />
      <!-- <TicketsHubComponent v-if="user && routeName !== 'affiliates'" /> -->
    </v-main>
  </v-app>
</template>
<script>
import { fb } from "@/firebase";
import Navigation from "@/components/navigation/_NavigationComponent.vue";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: { Navigation },
  data() {
    return {
      showSpinner: false,
      visitedPages: new Set(),
    };
  },
  computed: {
    ...mapGetters(["profileMode"]),
    ...mapState([
      "onlineAccounts",
      "currentHour",
      "liveStreamer",
      "liveStreamerRoute",
      "route",
      "routeName",
      "currentTicketProfile",
    ]),
    ...mapState("UserStore", [
      "user",
      "profileUserId",
      "profileUsername",
      "profileAdmin",
      "profileAdminLevel",
    ]),
    ...mapState("AccountStore", ["account"]),
    ...mapState("SubscriptionsStore", ["accountSubscription"]),
  },
  methods: {
    ...mapActions([
      "GET_STORE",
      "GET_STREAMERS",
      "GET_ACCOUNT_RANKINGS",
      "GET_LIVE_STREAMER",
      "CHECK_PROFILE_COINS",
      "GET_ACTIVE_TOURNEYS",
      "GET_ACTIVE_GIVEAWAYS",
      "GET_PROFILE_TICKETS",
      "GET_TICKETS",
      "GET_CURRENT_MESSAGES",
      "GET_RTDB_GIVEAWAY",
      "GET_COINS_RANKINGS",
      "GET_CREDITS_RANKINGS",
    ]),
    ...mapActions("UserStore", [
      "GET_USER",
      "GET_USER_STATE",
      "GET_USERS_STATE",
      "GET_CURRENT_RTDB_USER",
      "BUILD_USER_PRESENCE",
      "GET_USER_TOURNEYS",
    ]),
    ...mapActions("AccountStore", [
      "GET_ACCOUNT",
      "GET_ACCOUNT_TOURNEYS",
      "GET_ACCOUNT_EXP",
      "GET_ACCOUNT_GIVEAWAYS",
    ]),
    ...mapActions("SubscriptionsStore", [
      "GET_ACCOUNT_SUBSCRIPTION",
      "GET_SUBSCRIPTION",
      "GET_PRODUCTS",
    ]),
    ...mapActions("AffiliatesStore", [
      "GET_AFFILIATES_EXP",
      "GET_AFFILIATES_LEADERBOARD",
    ]),
    async fetchUserData(user) {
      await this.GET_USER(user);
      this.GET_ACTIVE_TOURNEYS();
      this.GET_ACTIVE_GIVEAWAYS();
      if (this.profileAdmin || this.profileAdminLevel === "Mod") {
        this.GET_TICKETS();
      } else {
        this.GET_PROFILE_TICKETS();
      }
      this.GET_PRODUCTS().then(this.GET_SUBSCRIPTION(user.uid));
      this.CHECK_PROFILE_COINS(user);
      if (this.routeName === "affiliates") {
        this.GET_AFFILIATES_EXP();
        this.GET_AFFILIATES_LEADERBOARD();
      }
      if (this.routeName === "profile") {
        await this.GET_ACCOUNT(this.route);
        await this.GET_ACCOUNT_SUBSCRIPTION(this.route);
        if (this.accountSubscription) {
          await this.GET_ACCOUNT_EXP(this.account.userId);
          await this.GET_ACCOUNT_GIVEAWAYS(this.account.userId);
        }
      }
      await this.BUILD_USER_PRESENCE(user.uid);
      this.GET_USER_STATE();
    },
    async handleAuthStateChange(user) {
      this.$store.commit("UserStore/UPDATE_USER", user);
      if (this.user) {
        await this.GET_CURRENT_RTDB_USER(user);
        await this.fetchUserData(user);
        this.GET_ACCOUNT_TOURNEYS(this.account.userId);
      } else {
        this.GET_ACTIVE_TOURNEYS();
        this.GET_ACTIVE_GIVEAWAYS();
        if (this.routeName === "profile") {
          await this.GET_ACCOUNT(this.route);
          this.GET_ACCOUNT_TOURNEYS(this.account.userId);
        }
      }
    },
    async showSpinnerOnFirstVisit(newPath) {
      if (!this.visitedPages.has(newPath)) {
        this.showSpinner = true;
        this.visitedPages.add(newPath);
        setTimeout(() => {
          this.showSpinner = false;
        }, 1000);
      }
    },
  },
  created() {
    this.visitedPages.add(this.$route.path);
    const spinnerShown = sessionStorage.getItem("spinnerShown");
    if (!spinnerShown) {
      sessionStorage.setItem("spinnerShown", "true");
    }
    this.GET_STORE();
    this.GET_STREAMERS();
    this.GET_COINS_RANKINGS();
    this.GET_CREDITS_RANKINGS();
    if (this.routeName === "giveaway") {
      this.GET_RTDB_GIVEAWAY(this.route);
    }
    fb.auth().onAuthStateChanged(this.handleAuthStateChange);
  },
};
</script>
<style>
.spinner {
  /* Style your spinner as needed */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
  z-index: 9999;
}
.v-badge__wrapper {
  z-index: 20;
}
.mainBg {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgb(0, 120, 218) 81%
  );
}
.mainBg1 {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgb(0, 120, 218) 81%
  );
}
.mainBg2 {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgba(0, 210, 175, 0.992) 91%
  );
}
.sheetBg {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgb(0, 120, 218) 81%
  );
}

.navBg {
  background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 50%);
  height: 100%;
}
.margTopHero {
  margin-top: 50px;
}
.padTopHero {
  padding-top: 50px;
}
.separateNavMain {
  margin-top: 100px;
}
.separateSections {
  margin-top: 75px;
}
.separateTitle {
  margin-bottom: 75px;
}
.app {
  background: white;
  color: black;
}
.roundedCard {
  border-radius: 35px !important;
}
.dark {
  background: black;
  color: white;
}
.router-link {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}
.striped-button {
  font-weight: bold;
  padding: 8px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  background: linear-gradient(
    135deg,
    #00e1ff 25%,
    transparent 25%,
    transparent 50%,
    #00e1ff 50%,
    #00e1ff 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  background-color: #f0fff0 !important;
  color: #00e1ff; /* Lime green text color */
  border: 2px solid #00e1ff;
  border-radius: 5px;
}

/* Hover effect */
.striped-button:hover {
  background: linear-gradient(
    135deg,
    #00e1ff 25%,
    transparent 25%,
    transparent 50%,
    #00e1ff 50%,
    #00e1ff 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  color: #00e1ff; /* Dark purple hover color */
}
.striped-button3 {
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  background: linear-gradient(
    135deg,
    #00ff51 25%,
    transparent 25%,
    transparent 50%,
    #00ff51 50%,
    #00ff51 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  background-color: #f0fff0 !important;
  color: #00ff51; /* Lime green text color */
  border: 2px solid #00ff51;
  border-radius: 5px;
}

/* Hover effect */
.striped-button3:hover {
  background: linear-gradient(
    135deg,
    #00ff51 25%,
    transparent 25%,
    transparent 50%,
    #00ff51 50%,
    #00ff51 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  color: #00ff51; /* Dark purple hover color */
}
.bordered-card {
  border: 2px solid #ffc107;
  border-radius: 5px;
  padding-top: 4px;
}
.striped-button2 {
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  background: linear-gradient(
    135deg,
    #ffc107 25%,
    transparent 25%,
    transparent 50%,
    #ffc107 50%,
    #ffc107 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  background-color: black !important;
  color: #ffc107; /* Lime green text color */
  border: 2px solid #ffc107;
  border-radius: 5px;
}
.striped-button2:hover {
  background: linear-gradient(
    135deg,
    #ffc107 25%,
    transparent 25%,
    transparent 50%,
    #ffc107 50%,
    #ffc107 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  color: #ffc107; /* Dark purple hover color */
}
.inactiveOpacity {
  opacity: 0.5;
}
.inactive-card {
  position: relative;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Semi-transparent white background */
}

.inactive-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    black 5px,
    black 10px
  );
  opacity: 0.2; /* Increased opacity for more pronounced effect */
  pointer-events: none; /* Exclude the background from capturing events */
}

.inactive-text {
  color: #333; /* Darker color for the text */
  font-style: italic; /* Add any additional styling for inactive text */
}

.reverted-styles {
  color: #993333;
  /* Adjust other styles as needed */
}

/* Styles for buttons that should not be affected by the background */
.reverted-styles .unaffected-button {
  background-color: blue !important; /* Use !important to override other styles */
  color: #fff !important; /* Set text color to white for visibility */
  opacity: 1 !important; /* Set opacity to 1 to make it fully opaque */
}
</style>
