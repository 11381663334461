import Vue from "vue";
import VueRouter from "vue-router";
import { fb, db, auth } from "@/firebase";
import store from "@/store";

Vue.use(VueRouter);
const beforeEnterGuard = async (to, from, next) => {
  if (fb.auth().currentUser && fb.auth().currentUser.uid) {
    const userData = await fetchUserData();
    if ((userData && userData.affiliates) || userData.username === "vforce") {
      next();
    } else {
      next("/");
    }
  }
};
async function fetchUserData() {
  let userId = "";
  if (fb.auth().currentUser && fb.auth().currentUser.uid) {
    userId = fb.auth().currentUser.uid;
    if (userId) {
      try {
        const doc = await db.collection("exp").doc(userId).get();
        return doc.data();
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      return null;
    }
  }
}
const routes = [
  // Testing Routes for trying out new things
  {
    path: "/testing/",
    name: "testing",
    component: () => import("../views/_test/_TestView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },

  {
    path: "/",
    name: "home",
    component: () => import("../views/home/_HomeView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: "/required-info/",
    name: "required-info",
    component: () => import("../views/auth/_RequiredInfoView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: "/giveaway/:id",
    name: "giveaway",
    component: () =>
      import("../views/profile/giveawaysProfile/_WinnersGiveawayView.vue"),
    meta: {
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("../views/auth/_ForgotPassword.vue"),
    beforeEnter(to, from, next) {
      const user = fb.auth().currentUser;
      if (user) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/_LoginView.vue"),
    meta: { requiresAuth: false, guest: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/auth/_LogoutView.vue"),
    beforeEnter: async (to, from, next) => {
      try {
        await fb.auth().signOut();
        next("/");
      } catch (error) {
        console.error("Error signing out:", error);
        next("/");
      }
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/auth/_SignupView.vue"),
    meta: { requiresAuth: false, guest: true },
  },
  {
    path: "/article/:id",
    name: "ArticleView",
    component: () => import("../views/articles/article/_ArticleBlogView.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/merch/",
    name: " merch",
    component: () => import("../views/store/_StoreView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },
  // {
  //   path: "/schedule/",
  //   name: "schedule",
  //   component: () => import("../views/schedule/_ScheduleView.vue"),
  //   meta: { requiresAuth: true, requiresAdmin: false },
  // },
  {
    path: "/team/",
    name: "team",
    component: () => import("../views/team/_TeamView.vue"),
    meta: { requiresAuth: false, requiresAdmin: false },
  },
  {
    path: "/about/",
    name: "about",
    component: () => import("../views/about/AboutView.vue"),
    meta: { requiresAuth: false, requiresAdmin: false },
  },
  {
    path: "/admin/",
    name: "admin",
    component: () => import("../views/admin/_AdminView.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerified: true,
    },
  },
  // {
  //   path: "/vlive/:id/:id",
  //   name: "vlive",
  //   component: () => import("../views/vlive/_VLiveView.vue"),
  //   meta: { requiresAdmin: false },
  // },
  {
    path: "/vpass",
    name: "vpass",
    component: () => import("../views/vpass/_VPassView.vue"),
    meta: { requiresAdmin: false },
  },
  {
    path: "/giveaways/",
    name: "giveaways",
    component: () => import("../views/giveaways/_GiveawaysView.vue"),
    meta: { requiresAdmin: false, requiresEmailVerified: false },
  },
  // {
  //   path: "/customs/",
  //   name: "customs",
  //   component: () => import("../views/customs/_CustomsView.vue"),
  //   meta: {
  //     requiresAuth: false,
  //     requiresAdmin: false,
  //   },
  // },
  // {
  //   path: "/customs/:id",
  //   name: "customsId",
  //   component: () => import("../views/customs/_CustomView.vue"),
  //   meta: {
  //     requiresAuth: false,
  //     requiresAdmin: false,
  //   },
  // },
  {
    path: "/tourneys/",
    name: "tourneys",
    component: () => import("../views/tourneys/_TourneysView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    },
  },
  {
    path: "/tourney/:username/:id",
    name: "tourney",
    component: () => import("../views/tourneys/_TourneyView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    },
  },
  {
    path: "/affiliates/",
    name: "affiliates",
    component: () => import("../views/affiliates/_AffiliatesView.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerified: true,
      requiresAdmin: false,
    },
    beforeEnter: beforeEnterGuard,
  },
  {
    path: "/ranks/",
    name: "ranks",
    component: () => import("../views/ranks/_GetRankView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },
  {
    path: "/tourneys/money-prizes/",
    name: "tourneysMoney",
    component: () =>
      import("../views/tourneys/moneyTourneys/_MoneyTourneysView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    },
  },
  {
    path: "/tourneys/exp-prizes",
    name: "tourneysExp",
    component: () =>
      import("../views/tourneys/expTourneys/_ExpTourneysView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    },
  },
  {
    path: "/store/",
    name: "store",
    component: () => import("../views/coins/_CoinsView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },
  {
    path: "/successful-transaction/",
    name: "successfulTransaction",
    component: () =>
      import("../views/coins/payments/_SuccessfulTransactionView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },
  {
    path: "/failed-transaction/",
    name: "failedTransaction",
    component: () =>
      import("../views/coins/payments/_FailedTransactionView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: "/settings/",
    name: "settings",
    component: () => import("../views/settings/_SettingsView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: "/:id",
    name: "profile",
    component: () => import("../views/profile/_ProfileView.vue"),
    alias: ["/:id/socials", "/:id/giveaways"],
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: true,
    },
  },
  // {
  //   path: "/:id/chat",
  //   name: "chat",
  //   component: () =>
  //     import("../views/profile/liveProfile/_LiveProfileView.vue"),
  //   meta: {
  //     requiresAuth: false,
  //     requiresAdmin: false,
  //     guest: false,
  //     requiresEmailVerified: true,
  //   },
  // },
  {
    path: "/:id/leaderboard",
    name: "leaderboard",
    component: () =>
      import("../views/profile/leaderboard/_LeaderboardView.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: "/verify-email",
    name: "verifyEmail",
    component: () => import("../components/VerifyEmail.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: "/legal/privacy-policy",
    name: "privacyPolicy",
    component: () => import("../views/legal/PrivacyPolicy.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: "/legal/terms-and-conditions",
    name: "terminAndConditions",
    component: () => import("../views/legal/Terms&Conditions.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: "*",
    component: () => import("../components/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // Changed to async function
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresEmailVerified = to.matched.some(
    (record) => record.meta.requiresEmailVerified
  );
  const requiresGuest = to.matched.some((record) => record.meta.guest);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  let user = auth.currentUser; // Get current user from auth

  if (!user) {
    // Wait for the authentication state to change
    const unsubscribe = auth.onAuthStateChanged((newUser) => {
      unsubscribe(); // Unsubscribe to prevent memory leaks
      user = newUser;

      checkUser(user);
    });
  } else {
    checkUser(user);
  }

  async function checkUser(user) {
    let admin = false;
    let hasUsername = false;
    let hasBirthday = false;

    if (user) {
      const token = await user.getIdTokenResult();
      admin = !!token.claims.admin;

      // Use fb object to access collections
      const accountDoc = await db.collection("accounts").doc(user.uid).get();
      if (accountDoc.exists) {
        const userData = accountDoc.data();
        hasUsername = !!userData.username;
        hasBirthday = !!userData.birthday;

        if ((!hasUsername && to.name !== "required-info") || !hasBirthday) {
          return next({ name: "required-info" });
        }
      }
    }

    if (requiresGuest && user) {
      return next({ name: "Home" });
    }

    if (user && requiresEmailVerified && !user.emailVerified) {
      return next({ name: "VerifyEmail" });
    }

    if (requiresAuth && !user) {
      return next({ name: "Login" });
    }

    if (requiresAuth && requiresAdmin && !admin) {
      return next({ name: "Home" });
    }
    next();
  }

  store.commit("SET_ROUTE", to);
  store.commit("ROUTE_LOADED", true);
  next();
});

export default router;
