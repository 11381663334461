const state = {};

const mutations = {
  // SET_ALL_RANKS(state, payload) {
  //   state.allRanks.push(payload);
  //   if (payload.fortniteBuildsLevel === "Gold III") {
  //     state.rankingsSweatI.push(payload);
  //   } else if (payload.fortniteBuildsLevel === "Gold II") {
  //     state.rankingsSweatII.push(payload);
  //   } else if (payload.fortniteBuildsLevel === "Gold I") {
  //     state.rankingsSweatIII.push(payload);
  //   } else if (payload.fortniteBuildsLevel === "Bronze III") {
  //     state.rankingsCasualI.push(payload);
  //   } else if (payload.fortniteBuildsLevel === "Bronze II") {
  //     state.rankingsCasualII.push(payload);
  //   } else if (payload.fortniteBuildsLevel === "Bronze I") {
  //     state.rankingsCasualIII.push(payload);
  //   }
  // },
};

const getters = {};

const actions = {
  // async GET_ALL_RANKS({ commit, state }) {
  //   const dataBase = await db.collection("ranks");
  //   const dbResults = await dataBase.get();
  //   const allRanks = dbResults;
  //   allRanks.forEach((doc) => {
  //     if (!state.allRanks.some((rank) => rank.userId === doc.data().userId)) {
  //       const data = {
  //         userId: doc.data().userId,
  //         discordUsername: doc.data().discordUsername,
  //         fortniteUsername: doc.data().fortniteUsername,
  //         username: doc.data().username,
  //         fortniteBuildsLevel: doc.data().fortniteBuildsLevel,
  //         clips: doc.data().clips,
  //       };
  //       commit("SET_ALL_RANKS", data);
  //     }
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
