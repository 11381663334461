import { db, rtdb } from "@/firebase";
import router from "../../router";

const state = {
  account: {},
  accountUserId: null,
  accountUsername: null,
  accountMainGame: null,
  accounts: [],
  accountExp: null,
  accountExpTweets: [],
  accountExpTourneys: [],
  accountTourneyApplications: [],
  accountClips: [],
  accountTourneysCooldown: [],
  accountAllTourneys: [],
  accountMerch: [],
  accountRanks: [],
  loadedTourneyTotals: [],
  accountAllGiveaways: [],
  accountActiveGiveaways: [],
  accountGiveawaysCooldown: null,
  accountFortniteBuildsLevel: null,
  profileAccountExp: null,
  // Loaded state
  accountTourneysLoaded: false,
  accountTourneyLoaded: false,
  accountLoaded: false,
  accountsLoaded: false,
  tourneyBettingsTotalLoaded: false,
  accountExpLoaded: false,
  accountRanksLoaded: false,
  accountGiveawaysLoaded: false,
};

const mutations = {
  // Set mutations
  SET_ACCOUNTS(state, payload) {
    state.accounts.push(payload);
  },
  SET_ACCOUNT(state, payload) {
    state.account = payload.data();
    state.accountUserId = payload.data().userId;
    state.accountUsername = payload.data().username;
    state.accountMainGame = payload.data().mainGame;
  },
  SET_ACCOUNT_INITIALS(state) {
    let accountInitials =
      state.account.firstName.match(/(\b\S)?/g).join("") +
      state.account.lastName.match(/(\b\S)?/g).join("");
    state.account["accountInitials"] = accountInitials;
  },
  SET_ACCOUNT_TOURNEY(state, payload) {
    if (payload && payload.clips) {
      state.accountClips = payload.clips;
    }
    if (payload.cooldown) {
      state.accountTourneysCooldown = payload.cooldown;
    }
  },
  SET_ACCOUNT_RANKS(state, payload) {
    let data = payload.data();
    state.accountRanks = data;
    if (data && data.fortniteBuildsLevel) {
      state.accountFortniteBuildsLevel = data.fortniteBuildsLevel;
    }
  },
  RESET_ACCOUNT_TOURNEYS(state) {
    state.accountAllTourneys = [];
    state.accountExpTourneys = [];
    state.accountTourneyApplications = [];
  },
  SET_RTDB_TOURNEY_BETS(state, payload) {
    let index = state.accountAllTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });
    state.accountAllTourneys[index].totalAmount = payload.val();
  },
  ADD_LOADED_TOURNEY_TOTAL(state, docId) {
    // Check if the docId is not already in the array
    if (!state.loadedTourneyTotals.includes(docId)) {
      // Add the docId to the array
      state.loadedTourneyTotals.push(docId);
    }
  },
  SET_ACCOUNT_TOURNEYS(state, payload) {
    let containsCoins = false;
    let containsPrizes = false;
    if (payload.winnersCoins && payload.winnersCoins.length) {
      containsCoins = payload.winnersCoins.some(
        (item) => item.username === state.profileUsername
      );
    }
    if (payload.winnersPrizes && payload.winnersPrizes.length) {
      containsPrizes = payload.winnersPrizes.some(
        (item) => item.username === state.profileUsername
      );
    }

    if (containsCoins || containsPrizes) {
      payload.claimRewards = true;
    } else {
      payload.claimRewards = false;
    }
    if (payload.participants.length) {
      payload.participants.forEach((participant) => {
        if (participant.username === state.profileUsername) {
          payload.participant = true;
        } else {
          payload.participant = false;
        }
      });
    } else {
      payload.participant = false;
    }
    // if (payload.coinsParticipants.length) {
    //   payload.coinsParticipants.forEach((participant) => {
    //     if (participant.username === state.profileUsername) {
    //       payload.coinsParticipant = true;
    //     } else {
    //       payload.coinsParticipant = false;
    //     }
    //   });
    // } else {
    //   payload.coinsParticipant = false;
    // }

    state.accountAllTourneys.push(payload);
  },
  SET_ACCOUNT_GIVEAWAYS(state, payload) {
    let containsPrizes = false;
    if (payload.participants && state.profileUsername) {
      const participantStatus = payload.participants.some(
        (item) => item.username === state.profileUsername
      );
      if (participantStatus) {
        payload.participate = true;
      } else {
        payload.participate = false;
      }
    } else {
      payload.participate = false;
    }

    if (payload.winnersPrizes && payload.winnersPrizes.length) {
      containsPrizes = payload.winnersPrizes.some(
        (item) => item.username === state.profileUsername
      );
    } else if (payload.winners && payload.winners.length) {
      containsPrizes = payload.winners.some(
        (item) => item.username === state.profileUsername
      );
    }
    if (containsPrizes) {
      payload.claimRewards = true;
    } else {
      payload.claimRewards = false;
    }
    payload.totalAmount = state.totalMoneyAmount;
    state.accountAllGiveaways.push(payload);
  },
  SET_ACCOUNT_MERCH(state, payload) {
    state.accountMerch = payload.data();
  },
  SET_ACCOUNT_EXP_TWEETS(state, payload) {
    state.accountExpTweets.push(payload);
  },
  SET_ACCOUNT_EXP(state, payload) {
    state.accountExp = payload;
    state.accountExpList = payload.expList;
  },
  SET_PROFILE_EXP(state, payload) {
    if (payload.expList && payload.expList.length) {
      const index = payload.expList.findIndex((item) => {
        return item.username === state.profileUsername;
      });
      if (index !== -1) {
        state.profileAccountExp = payload.expList[index];
      } else {
        let updateUser = {
          username: state.profileUsername,
          twitterUsername: state.profileTwitterUsername,
          userExp: 0,
        };
        state.profileAccountExp = updateUser;
      }
    } else {
      let updateUser = {
        username: state.profileUsername,
        twitterUsername: state.profileTwitterUsername,
        userExp: 0,
      };
      state.profileAccountExp = updateUser;
    }
  },
  SET_ACCOUNT_GIVEAWAY(state, payload) {
    if (payload.cooldown) {
      state.accountGiveawaysCooldown = payload.cooldown;
    }
  },

  //Loaded mutations
  RTDB_TOURNEY_TOTAL_LOADED(state) {
    state.tourneyBettingsTotalLoaded = true;
  },
  ACCOUNTS_LOADED(state) {
    state.accountsLoaded = true;
  },
  ACCOUNT_LOADED(state) {
    state.accountLoaded = true;
  },
  ACCOUNT_TOURNEYS_LOADED(state) {
    state.accountTourneysLoaded = true;
  },
  ACCOUNT_EXP_LOADED(state) {
    state.accountExpLoaded = true;
  },
  ACCOUNT_RANKS_LOADED(state) {
    state.accountRanksLoaded = true;
  },
  ACCOUNT_TOURNEY_LOADED(state) {
    state.accountTourneyLoaded = true;
  },
  GIVEAWAYS_LOADED(state) {
    state.accountGiveawaysLoaded = true;
  },
};

const getters = {};

const actions = {
  async GET_ACCOUNTS({ commit, state }) {
    const dataBase = await db.collection("accounts");
    const dbResults = await dataBase.get();
    const accounts = dbResults;

    accounts.forEach((doc) => {
      if (
        !state.accounts.some(
          (account) => account.userId === doc.data().userId
        ) &&
        doc.data().userId
      ) {
        const data = {
          userId: doc.data().userId,
          username: doc.data().username,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          profilePhoto: doc.data().profilePhoto,
          created_at: doc.data().created_at,
          email: doc.data().email,
          followers: doc.data().followers,
          mode: doc.data().mode,
          rank: doc.data().rank,
          referral: doc.data().referral,
          streamer: doc.data().streamer,
        };
        commit("SET_ACCOUNTS", data);
      }
    });
    commit("SCHEDULE_LOADED");
  },
  async GET_ACCOUNT({ commit }, user) {
    const dataBase = await db
      .collection("accounts")
      .where("username", "==", user);
    const dbResults = await dataBase.get();
    const account = dbResults.docs[0];
    if (account == undefined) {
      // need to set routeName === 'tourney' check where this is called on tourney view
      router.push({ name: "home" });
    } else {
      // if (account.data().streamer && state.user) {
      //   let getAccountExp = await rtdb.ref(
      //     "/exp/" + fb.auth().currentUser.uid
      //   );
      //   getAccountExp.once("value", function (snapshot) {
      //     let data = snapshot;
      //     let username = account.data().username;
      //     if (!data.val()[username]) {
      //       let updateRanks = {
      //         [username + "Exp"]: 0,
      //         [username]: username,
      //       };
      //       rtdb.ref("exp/" + fb.auth().currentUser.uid).update(updateRanks);
      //     }
      //   });
      // }
      commit("SET_ACCOUNT", account);
      commit("SET_ACCOUNT_INITIALS");
      // let getRankings = await rtdb.ref("/exp/" + account.data().userId);
      // getRankings.once("value", function (snap) {
      //   commit("SET_RTDB_ACCOUNT_EXP", snap);
      // });
      commit("ACCOUNT_LOADED");
    }
  },
  async GET_ACCOUNT_EXP({ commit, state }, userId) {
    state.accountExp = {};
    state.accountExpTweets = [];
    const dataBase = await db.collection("exp").doc(userId);
    const dbResults = await dataBase.get();
    const accountExp = dbResults.data();
    let completedSteps = 1;
    let pendingSteps = 1;
    accountExp.tweets.forEach((doc) => {
      if (!state.accountExpTweets.some((tweet) => tweet.id === doc.id)) {
        if (
          doc.completedList &&
          doc.completedList.includes(state.profileTwitterUsername)
        ) {
          accountExp.step = completedSteps++;
          accountExp.currentStep = accountExp.step + 1;
          doc.stepCompleted = true;
          doc.stepStatus = "Completed";
        } else if (
          (doc.pendingList &&
            doc.pendingList.includes(state.profileTwitterUsername)) ||
          (doc.requests && doc.requests.includes(state.profileTwitterUsername))
        ) {
          {
            accountExp.pendingCount = pendingSteps++;
            doc.stepCompleted = true;
            doc.currentStep = accountExp.step + 1;
            doc.stepStatus = "Pending";
            doc.btnStatus = "Pending";
          }
          accountExp.currentStep = accountExp.step + 1;
          doc.stepCompleted = true;
          doc.stepStatus = "Pending";
        } else if (
          doc.pendingList &&
          !doc.pendingList.includes(state.profileTwitterUsername)
        ) {
          doc.stepCompleted = false;
          accountExp.currentStep = accountExp.step + 1;
          doc.stepStatus = "Complete";
          doc.btnStatus = "Complete";
        } else {
          doc.stepCompleted = false;
          doc.stepStatus = "Complete";
        }
        doc.link =
          "https://www.twitter.com/" +
          state.account.twitterUsername +
          "/status/" +
          doc.id;
        if (accountExp.step === undefined) {
          accountExp.step = 0;
          accountExp.currentStep = 1;
        }
        commit("SET_ACCOUNT_EXP_TWEETS", doc);
        commit("SET_ACCOUNT_EXP", accountExp);
      }
    });
    commit("SET_PROFILE_EXP", accountExp);
    commit("ACCOUNT_EXP_LOADED");
  },
  async GET_ACCOUNT_RANKS({ commit }, userId) {
    const dataBase = await db.collection("ranks").doc(userId);
    const dbResults = await dataBase.get();
    commit("SET_ACCOUNT_RANKS", dbResults);
    commit("ACCOUNT_RANKS_LOADED");
  },
  async GET_ACCOUNT_TOURNEYS({ dispatch, commit, state }, userId) {
    // Initialize state
    commit("RESET_ACCOUNT_TOURNEYS");

    try {
      const dataBase = await db.collection("tourneys").doc(userId).get();
      if (!dataBase.exists) {
        console.warn("No data found for user:", userId);
        return commit("ACCOUNT_TOURNEYS_LOADED");
      }

      const results = dataBase.data();
      commit("SET_ACCOUNT_TOURNEY", results);

      if (results && results.list) {
        await Promise.all(
          results.list.map(async (snap) => {
            await dispatch("GET_RTDB_TOURNEY_BETS", snap);
            if (
              !state.accountAllTourneys.some(
                (tourney) => tourney.docId === snap.docId
              )
            ) {
              processSnap(snap);
              commit("SET_ACCOUNT_TOURNEYS", { ...snap, id: snap.docId });
            }
          })
        );
      }
    } catch (error) {
      console.error("Error fetching account tourneys:", error);
      // Optionally, handle the error accordingly
    }

    commit("ACCOUNT_TOURNEYS_LOADED");

    // Helper functions
    function processSnap(snap) {
      setTweetStep(snap);
      setParticipationStatus(snap);
      setBuyInStatus(snap);
    }

    function setTweetStep(snap) {
      if (!state.profileTwitterStatus) {
        snap.tweetStep = 1;
      } else if (
        snap.tweetLikes &&
        snap.tweetLikes.includes(state.profileTwitterUsername)
      ) {
        snap.tweetStep = 3;
      } else if (
        snap.pendingList &&
        snap.pendingList.includes(state.profileTwitterUsername)
      ) {
        snap.tweetStep = 2;
        snap.stepCompleted = false;
        snap.stepStatus = "Pending";
      } else {
        snap.tweetStep = 2;
        snap.stepCompleted = false;
        snap.stepStatus = "Complete Step";
      }
    }

    function setParticipationStatus(snap) {
      snap.allowed = false;
      if (
        snap.allowedRanks &&
        (snap.allowedRanks.includes(state.profileFortniteBuildsLevel) ||
          snap.allowedRanks[0] === "Everyone")
      ) {
        snap.allowed = true;
      }
    }

    function setBuyInStatus(snap) {
      if (!snap.buyInCoins) {
        const hasValue = snap.participants.some(
          (obj) => obj.username === state.profileUsername
        );
        if (!hasValue && snap.buyInCoinsAmount > state.currentProfileRanking) {
          snap.disabled = true;
          snap.reason = "notEnough";
        } else {
          snap.disabled = false;
          snap.reason = "enough";
        }
      } else {
        if (snap.buyInCoinsAmount > state.profileCredits) {
          snap.disabled = true;
          snap.reason = "notEnough";
        } else {
          snap.disabled = false;
          snap.reason = "enough";
        }
      }
    }
  },
  async GET_RTDB_TOURNEY_BETS({ commit, state }, tourney) {
    // Check if the data for this tournament has already been loaded
    if (!state.loadedTourneyTotals.includes(tourney.docId)) {
      // If not loaded, fetch the data
      let getData = await rtdb.ref("/bets/" + tourney.docId);
      getData.once("value", function (snapshot) {
        if (snapshot.exists()) {
          commit("SET_RTDB_TOURNEY_BETS", snapshot);
        }
        // Mark this tournament as loaded
        commit("ADD_LOADED_TOURNEY_TOTAL", tourney.docId);
        // Commit the mutation indicating that the RTDB tournament total data is loaded
        commit("RTDB_TOURNEY_TOTAL_LOADED");
      });
    }
  },

  async GET_ACCOUNT_TOURNEY({ commit, state }, tourney) {
    state.accountTourney = [];
    let dataBase = await db
      .collection("tourneysFn")
      .where("username", "==", tourney.username)
      .where("active", "==", true);

    const dbResults = await dataBase.get();
    let results = dbResults.docs[0].data();
    if (results) {
      commit("SET_ACCOUNT_TOURNEY", {
        tourney: results,
        docId: tourney.docId,
      });
    }
    commit("ACCOUNT_TOURNEY_LOADED");
  },
  async GET_ADMIN_TOURNEYS({ commit, state }) {
    const dataBase = await db
      .collection("tourneys")
      .where("tourneyAdmin", "==", true);
    const dbResults = await dataBase.get();
    const tourneys = dbResults;
    tourneys.forEach((doc) => {
      if (
        !state.adminAllTourneys.some(
          (tourney) => tourney.userId === doc.data().userId
        )
      ) {
        const data = {
          list: doc.data().list,
          username: doc.data().username,
          userId: doc.data().userId,
        };
        commit("SET_ADMIN_TOURNEYS", data);
      }
    });
  },
  async GET_ACCOUNT_GIVEAWAYS({ commit, state }, userId) {
    console.log(userId);
    state.accountAllGiveaways = [];
    state.accountActiveGiveaways = [];
    let dataBase = db.collection("giveaways").doc(userId);
    const dbResults = await dataBase.get();
    let results = dbResults.data();
    if (results) {
      commit("SET_ACCOUNT_GIVEAWAY", results);
      if (results.list) {
        results.list.forEach(function (snap) {
          if (
            !state.accountAllGiveaways.some(
              (giveaway) => giveaway.docId === snap.docId
            )
          ) {
            if (!state.profileTwitterStatus) {
              snap.tweetStep = 1;
            } else if (
              snap.pendingList &&
              snap.pendingList.includes(state.profileTwitterUsername)
            ) {
              snap.tweetStep = 2;
              snap.stepCompleted = false;
              snap.stepStatus = "Pending";
            } else {
              snap.tweetStep = 2;
              snap.stepCompleted = false;
              snap.stepStatus = "Complete Step";
            }
            if (
              snap.tweetLikes &&
              snap.tweetLikes.includes(state.profileTwitterUsername)
            ) {
              snap.tweetStep = 3;
              snap.stepCompleted = false;
            }
            let data = {};
            if (state.profileAccountExp) {
              if (snap.expEnterAmount > state.profileAccountExp.userExp) {
                data = {
                  ...snap,
                  disabled: true,
                  reason: "notEnough",
                };
              } else {
                data = {
                  ...snap,
                  reason: "enough",
                  disabled: false,
                };
              }
            }
            commit("SET_ACCOUNT_GIVEAWAYS", data);
          }
        });
      }
    }
    commit("GIVEAWAYS_LOADED");
  },
  async GET_ACCOUNT_MERCH({ commit, state }) {
    state.accountMerch = [];
    const dataBase = await db.collection("merch").doc(state.account.userId);
    const dbResults = await dataBase.get();
    const merch = dbResults;
    if (merch) {
      commit("SET_ACCOUNT_MERCH", merch);
    }
  },

  // UPDATE actions
  async UPDATE_ACCOUNT({ dispatch }, account) {
    await db.collection("accounts").doc(account.userId).update(account);
    await dispatch("GET_ACCOUNTS");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
