<template>
  <div
    class="ma-0 pa-0 mx-2 mt-5"
    v-if="routeName !== 'admin' && routeName !== 'settings'"
  >
    <v-btn icon color="white " class="mb-5" dark @click="drawer = !drawer">
      <v-icon>mdi-swap-horizontal-bold</v-icon>
    </v-btn>
    <div
      class="pointer ma-0 pa-0"
      v-if="routeName !== 'admin'"
      ref="drawer"
      @click="toggleDrawer()"
    >
      <v-list
        class="pt-3 drawerListPad mainBg black"
        scrollable
        height="100vh"
        dense
        @click="drawer = !drawer"
        v-model="drawer"
        :class="[
          !drawer && $vuetify.breakpoint.smAndUp
            ? 'closeDrawer'
            : '' || (drawer && $vuetify.breakpoint.smAndUp)
            ? 'openDrawer'
            : '',
          !drawer && $vuetify.breakpoint.xs
            ? 'hideDrawerXs'
            : '' || (drawer && $vuetify.breakpoint.xs)
            ? 'showDrawerXs'
            : '',
        ]"
      >
        <!-- <v-card
          max-width="280px"
          elevation="0"
          :class="!drawer ? ['ml-0', 'pt-2'] : ['ml-2', 'pt-4']"
        >
          <v-btn color="purple" class="my-2" text v-if="!drawer && user"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
          <v-text-field
            v-if="drawer"
            v-model="search"
            solo
            color="purple"
            label="Search"
            @change="searchResult"
            append-icon="mdi-magnify"
            class="mr-1"
            v-show="user"
          ></v-text-field>
        </v-card> -->
        <div
          v-for="(streamer, i) in streamers"
          :key="'b' + i"
          v-show="streamer.subscription === true"
          @click="drawer = !drawer"
        >
          <v-list-item
            :to="`/${streamer.username}`"
            link
            class="px-2 pt-0 mb-2"
            :class="$vuetify.breakpoint.xs ? 'ml-5' : 'ml-0'"
          >
            <v-badge
              bordered
              bottom
              :color="
                streamer.status !== 'live' ? 'red accent-4' : 'green accent-4'
              "
              :dot="streamer.status !== 'live'"
              offset-x="20"
              offset-y="10"
              v-if="streamer.profilePhoto && streamer.profilePhoto.length"
            >
              <v-avatar size="35" class="ml-1 mr-1 white">
                <v-img
                  cover
                  :src="streamer.profilePhoto"
                  :lazy-src="streamer.profilePhoto"
                  alt="alt"
                />
              </v-avatar>
            </v-badge>
            <v-badge
              bordered
              bottom
              :color="
                streamer.state !== 'online' ? 'red accent-4' : 'green accent-4'
              "
              :dot="streamer.state !== 'online'"
              offset-x="20"
              offset-y="10"
              v-if="!streamer.profilePhoto"
            >
              <v-avatar size="35" class="ml-1 mr-1 white">
                <h4 class="white--text">VF</h4>
              </v-avatar>
            </v-badge>

            <v-col cols="7" class="my-0 py-0">
              <v-list-item-title
                v-if="!streamer.displayName"
                class="d-inline-flex white--text text-uppercase"
                >{{ streamer.username }}</v-list-item-title
              >
              <v-list-item-title
                class="d-inline-flex white--text text-uppercase"
                v-else
                >{{ streamer.displayName }}</v-list-item-title
              >
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-btn
                :to="`/${streamer.username}`"
                v-if="drawer"
                @click.stop="drawer = !drawer"
                x-small
                icon
                fab
                @click="GET_ACCOUNT(streamer.username)"
                color="black"
                class="white mr-2"
                ><v-icon>mdi-account-arrow-right</v-icon></v-btn
              >
            </v-col>
          </v-list-item>
        </div>
        <v-tabs
          v-model="tab"
          class="black mt-2"
          color="black"
          background-color="black"
          :slider-color="!drawer ? 'black' : 'white'"
        >
          <v-tab class="white--text">Coins</v-tab>
          <v-tab class="white--text">Credits</v-tab>

          <v-tabs-items v-model="tab" class="black">
            <v-tab-item class="black">
              <LeaderboardComponent
                @update:drawer="handleDrawerUpdate"
                :leaderboard="{
                  rankings: coinsRankings,
                  type: 'coins',
                  drawer: drawer,
                }"
              />
            </v-tab-item>
            <v-tab-item class="black">
              <LeaderboardComponent
                @update:drawer="handleDrawerUpdate"
                :leaderboard="{
                  rankings: creditsRankings,
                  type: 'credits',
                  drawer: drawer,
                }"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <!-- <div
          v-for="(item, i) in searchResult"
          :key="'B' + i"
          class="pt-1"
          v-show="search && searchResult"
        >
          <v-list-item
            link
            class="px-2 pt-0"
            v-if="item.username !== profileUsername && item.streamer !== true"
          >
            <v-badge
              bordered
              bottom
              :color="
                item.state !== 'online' ? 'red accent-4' : 'green accent-4'
              "
              :dot="item.state !== 'online'"
              offset-x="20"
              offset-y="10"
              v-if="item.profilePhoto"
            >
                            <v-avatar size="35" class="ml-1 mr-1 white">
                <v-img
                  cover
                  :src="item.profilePhoto"
                  :lazy-src="item.profilePhoto"
                  alt="alt"
                />
              </v-avatar>
            </v-badge>

            <v-badge
              bordered
              bottom
              :color="
                item.state !== 'online' ? 'red accent-4' : 'green accent-4'
              "
              :dot="item.state !== 'online'"
              offset-x="20"
              offset-y="10"
              v-if="!item.profilePhoto"
            >
                            <v-avatar size="35" class="ml-1 mr-1 white"> 
                <h4 class="black--text">{{ item.profileInitials }}</h4>
              </v-avatar>
            </v-badge>

            <v-col cols="7" class="my-0 py-0">
              <v-list-item-title
                v-if="!item.displayName"
                class="d-inline-flex text-uppercase"
                >{{ item.username }}</v-list-item-title
              >
              <v-list-item-title class="d-inline-flex text-uppercase" v-else>{{
                item.displayName
              }}</v-list-item-title>
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-btn
                :to="`/${item.username}`"
                v-if="drawer"
                @click.stop="drawer = !drawer"
                x-small
                icon
                fab
                color="black"
                class="white mr-2"
                ><v-icon>mdi-account-arrow-right</v-icon></v-btn
              ></v-col
            >
          </v-list-item>
        </div>
        <v-row justify="center">
          <v-subheader v-if="!searchResult.length && streamerSearch.length">
            No results...
          </v-subheader>
        </v-row> -->
      </v-list>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import LeaderboardComponent from "@/views/profile/leaderboard/_LeaderboardView.vue";
export default {
  name: "NavigationComponent",
  components: { LeaderboardComponent },
  data() {
    return {
      drawer: false,
      drawerCloserStatus: false,
      search: "",
      streamerSearch: "",
      items: ["All users", "Referrals"],
      tab: 0,
    };
  },

  computed: {
    ...mapState([
      "user",
      "accountsState",
      "profileUsername",
      "profileInitials",
      "profilePhoto",
      "streamers",
      "routeName",
      "coinsRankings",
      "creditsRankings",
    ]),
    searchResult() {
      let accounts = this.accountsState;

      if (this.search != "" && this.search) {
        accounts = accounts.filter((item) => {
          return item.username
            .toUpperCase()
            .includes(this.search.toUpperCase());
        });
      }
      return accounts;
    },
    searchStreamerResult() {
      let streamerAccounts = this.streamers;
      if (this.streamerSearch != "" && this.streamerSearch) {
        streamerAccounts = streamerAccounts.filter((item) => {
          return item.username
            .toUpperCase()
            .includes(this.streamerSearch.toUpperCase());
        });
      }
      return streamerAccounts;
    },
  },
  methods: {
    ...mapActions("AccountStore", ["GET_ACCOUNT"]),

    handleDrawerUpdate(status) {
      this.drawerCloserStatus = status;
    },
    toggleDrawer() {
      if (!this.drawer && !this.drawerCloserStatus) {
        this.drawer = true;
      } else if (this.drawer && this.drawerCloserStatus) {
        this.drawer = false;
        this.drawerCloserStatus = false;
      } else {
        this.drawer = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.drawer.contains(event.target)) {
        this.drawer = false;
      }
    },
  },
};
</script>
<style scoped>
:deep(.v-badge--bordered .v-badge__badge) {
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
}

.closeDrawer {
  position: fixed;
  top: 64px;
  z-index: -10;
  left: 0px;
  width: 62px;
}
.hideDrawerXs {
  position: fixed;
  top: 64px;
  z-index: -10;
  left: -300px;
}

.openDrawer {
  position: fixed;
  top: 64px;
  width: 335px;
  left: 0px;
}
.showDrawerXs {
  position: fixed;
  top: 56px;
  width: 370px;
  left: 0;
}
.closeBtn {
  position: fixed;
  top: 84px;
  left: 64px !important;
  z-index: 10;
}
.openBtn {
  position: fixed;
  top: 82px !important;
  left: 285px !important;
  z-index: 10;
}
.hideBtnXs {
  position: fixed;
  top: 65px;
  left: 5px;
}
.showBtnXs {
  position: fixed;
  top: 84px;
  left: 330px;
  color: black;
  z-index: 12;
}
.v-list {
  height: 100vh; /* or any height you want */
  overflow-y: auto;
}
:deep(.v-text-field__details) {
  display: none;
}
:deep(.v-messages.theme--light) {
  display: none;
}
.drawerListPad {
  padding-bottom: 70px;
}
</style>
